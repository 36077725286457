export default [
  {
    path: '/licences',
    component: () => import(
      /* webpackChunkName: "licence" */
      '@/pages/lms/product/config/LicenceManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "licence" */
          '@/components/lms/product/licences/List.vue'
        ),
        name: 'licence-list',
        meta: {
          permissions: ['lms_licences.view_licence'],
        },
      },
    ],
  },
];
