export default [
  {
    path: 'application-portals',
    component: () => import(
      /* webpackChunkName: "subject-lms-app-portals" */
      '@/pages/lms/public/applicationPortals.vue'
    ),
    name: 'appliction-portals',
    children: [
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "subject-lms-app-portals" */
          '@/components/lms/public/applicationPortals/Apply.vue'
        ),
        props: true,
        name: 's:appliction-portals-apply',
      },
    ],
  },
  {
    path: '/s/lms/council-list',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSCouncilList.vue'
    ),
    props: false,
    name: 's:lms-council-list',
  },
  {
    path: '/s/lms/:councilId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSCouncilDetail.vue'
    ),
    props: true,
    name: 's:lms-council-detail',
  },
  {
    path: '/s/lms/:councilId/:licenceGroupId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSLicenceGroupDetail.vue'
    ),
    props: true,
    name: 's:lms-licence-group-detail',
  },
  {
    path: '/s/lms/:councilId/:licenceGroupId/:licenceSubGroupId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSLicenceSubGroupDetail.vue'
    ),
    props: true,
    name: 's:lms-licence-sub-group-detail',
  },
];
