export default [
  {
    path: '/application-portals',
    component: () => import(
      /* webpackChunkName: "subject-lms-app-portals" */
      '@/pages/lms/public/applicationPortals.vue'
    ),
    name: 'public-appliction-portals',
    children: [
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "subject-lms-app-portals" */
          '@/components/lms/public/applicationPortals/Apply.vue'
        ),
        props: true,
        name: 'public-appliction-portals-apply',
        meta: { public: true, hideBreadcrumbs: true },
      },
    ],
  },
];
