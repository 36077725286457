import { viewsetActions } from '@/service/plugin';

export default {
  applicationPortals: viewsetActions('application-portals', {
    basePath: '/api/lms/s/',
    extraActions: {
      apply: { path: 'apply', method: 'post', detail: true },
    },
  }),
};
