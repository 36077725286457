import { viewsetActions } from '@/service/plugin';

const licenceCustomers = viewsetActions('customers', { basePath: '/api/lms/public/' });
const licenceGroups = viewsetActions('licence-groups', { basePath: '/api/lms/public/' });
const licenceSubGroups = viewsetActions('licence-sub-groups', { basePath: '/api/lms/public/' });

export default {
  licenceCustomers,
  licenceGroups,
  licenceSubGroups,
};
