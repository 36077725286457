export default [
  {
    path: '/licence-groups',
    component: () => import(
      /* webpackChunkName: "licence-groups" */
      '@/pages/lms/product/config/GroupManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "licence-groups" */
          '@/components/lms/product/groups/List.vue'
        ),
        name: 'licence-group-list',
        meta: {
          permissions: ['lms_definitions.view_licencegroup'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "licence-groups" */
          '@/components/lms/product/groups/Add.vue'
        ),
        name: 'licence-group-add',
        meta: {
          permissions: ['lms_definitions.add_licencegroup'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "licence-groups" */
          '@/components/lms/product/groups/Detail.vue'
        ),
        props: true,
        meta: {
          permissions: ['lms_definitions.view_licencegroup'],
        },
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "licence-groups" */
              '@/components/lms/product/groups/detail_tabs/Overview.vue'
            ),
            name: 'licence-group-overview',
            meta: {
              permissions: ['lms_definitions.view_licencegroup'],
            },
          },
          {
            path: 'details/',
            component: () => import(
              /* webpackChunkName: "licence-groups" */
              '@/components/lms/product/groups/detail_tabs/Details.vue'
            ),
            name: 'licence-group-details',
            meta: {
              permissions: ['lms_definitions.change_licencegroup'],
            },
          },
          {
            path: 'sub-groups/',
            component: () => import(
              /* webpackChunkName: "licence-groups" */
              '@/components/lms/product/groups/detail_tabs/sub_groups/List.vue'
            ),
            name: 'licence-sub-group-list',
            meta: {
              permissions: ['lms_definitions.view_licencesubgroup'],
            },
          },
          {
            path: 'sub-groups/add',
            component: () => import(
              /* webpackChunkName: "licence-groups" */
              '@/components/lms/product/groups/detail_tabs/sub_groups/Add.vue'
            ),
            name: 'licence-sub-group-add',
            meta: {
              permissions: ['lms_definitions.add_licencesubgroup'],
            },
          },
          {
            path: 'sub-groups/:subGroupId',
            props: true,
            component: () => import(
              /* webpackChunkName: "licence-groups" */
              '@/components/lms/product/groups/detail_tabs/sub_groups/Details.vue'
            ),
            name: 'licence-sub-group-details',
            meta: {
              permissions: ['lms_definitions.change_licencesubgroup'],
            },
          },
        ],
      },
    ],
  },
];
