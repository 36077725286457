export default [
  {
    path: '/lms/council-list',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSCouncilList.vue'
    ),
    props: false,
    name: 'lms-council-list',
    meta: {
      redirectSubjects: true,
    },
  },
  {
    path: '/lms/:councilId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSCouncilDetail.vue'
    ),
    props: true,
    name: 'lms-council-detail',
    meta: {
      redirectSubjects: true,
    },
  },
  {
    path: '/lms/:councilId/:licenceGroupId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSLicenceGroupDetail.vue'
    ),
    props: true,
    name: 'lms-licence-group-detail',
    meta: {
      redirectSubjects: true,
    },
  },
  {
    path: '/lms/:councilId/:licenceGroupId/:licenceSubGroupId',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/lms/public/LMSLicenceSubGroupDetail.vue'
    ),
    props: true,
    name: 'lms-licence-sub-group-detail',
    meta: {
      redirectSubjects: true,
    },
  },
];
