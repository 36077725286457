import Vue from 'vue';

import { RestService, callbacks } from '@/service/plugin';
import store from '@/store';

import productResources from './product';
import publicResources from './public';
import subjectResources from './subject';
import lmsResources from './lms';

const getDuplicateQueryMessage = (query) => {
  let msg = 'Duplicate query detected:';
  msg += `\n\n\t${query}\n\n\n`;
  msg += 'See more details using the Debug Toolbar History panel on the browsable API page.';
  return msg;
};

if (process.env.NODE_ENV === 'development') {
  RestService.axios.interceptors.response.use((response) => {
    if ('duplicate-query' in response.headers) {
      alert(getDuplicateQueryMessage(response.headers['duplicate-query']));  // eslint-disable-line
    }
    return response;
  });
}

Vue.use(RestService);

const getErrorMessage = (axiosError, options = {}) => {
  let defaultMessage = 'An error has occurred';
  const codeErrors = options.codeErrorMessages;
  if (axiosError.response && codeErrors && axiosError.response.status in codeErrors) {
    defaultMessage = codeErrors[axiosError.response.status];
  } else if (options.showBackEndErrors) {
    const errors = axiosError.response.data;
    defaultMessage += '\n';
    Object.keys(errors).forEach((field) => {
      defaultMessage += `${field}: `;
      errors[field].forEach((error) => {
        defaultMessage += `${error}\n`;
      });
    });
  } else if (options.viewset) {
    const { resource } = options.viewset;
    const resourceSingular = options.viewset.config.resourceSingular || resource.slice(0, -1);
    defaultMessage = {
      list: `Error getting list of ${resource}`,
      retrieve: `Error retrieving ${resourceSingular}`,
      create: `Error adding ${resourceSingular}`,
      update: `Error updating ${resourceSingular}`,
      partialUpdate: `Error updating ${resourceSingular}`,
      delete: `Error deleting ${resourceSingular}`,
    }[options.viewset.action] || defaultMessage;
  }
  return options.errorMessage || defaultMessage;
};

const getSuccessMessage = (options = {}) => {
  let defaultMessage = 'Action performed successfully';
  if (options.viewset) {
    const { resource } = options.viewset;
    const resourceSingular = options.viewset.config.resourceSingular || resource.slice(0, -1);
    defaultMessage = {
      create: `Successfully added ${resourceSingular}`,
      update: `Successfully updated ${resourceSingular}`,
      partialUpdate: `Successfully updated ${resourceSingular}`,
      delete: `Successfully deleted ${resourceSingular}`,
    }[options.viewset.action] || defaultMessage;
  }
  return options.successMessage || defaultMessage;
};

callbacks.success = (response, options = {}) => {
  const sendMessage = ['post', 'put', 'patch', 'delete'].includes(response.config.method);
  if (sendMessage && !options.skipSuccessMessage) {
    store.commit('notifications/notify', { text: getSuccessMessage(options), type: 'success' });
  }
  if (options.setAuditLogParams) {
    store.commit('ui/setAuditLogParams', response.data.audit_log_params);
  }
  return response;
};

callbacks.error = (axiosError, options = {}) => {
  const error = { ...axiosError };
  if (!(options.skipErrorMessage || (error.response && error.response.status === 404))) {
    const { notificationTypes } = options;
    const notificationType = notificationTypes && error.response.status in notificationTypes ? notificationTypes[error.response.status] : 'error';
    store.commit(
      'notifications/notify',
      { text: getErrorMessage(axiosError, options), type: notificationType },
    );
  }
  if (options.errorResponseData) {
    error.response.data = options.errorResponseData;
  } else if (options.viewset && options.viewset.action === 'list') {
    error.response.data = { results: [], count: 0 };
  }
  return error.response;
};

const resources = {
  product: productResources,
  public: publicResources,
  subject: subjectResources,
  lms: lmsResources,
};

export default new RestService({
  resources,
});
