import { viewsetActions } from '@/service/plugin';

const groups = viewsetActions(
  'licence-groups',
  {
    basePath: '/api/lms/',
  },
);
const subGroups = viewsetActions(
  'licence-sub-groups',
  {
    basePath: '/api/lms/',
  },
);

export default {
  groups,
  subGroups,
};
