import { viewsetActions } from '@/service/plugin';

const applicationPortals = viewsetActions(
  'application-portals',
  {
    basePath: '/api/lms/',
    extraActions: {
      enable: { path: 'enable', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
      togglePublic: { path: 'toggle_is_public', method: 'post', detail: true },
      toggleDefault: { path: 'toggle_is_default', method: 'post', detail: true },
    },
  },
);

const licenceSubGroups = viewsetActions(
  'licence-sub-groups',
  {
    basePath: '/api/lms/',
  },
);

const licenceGroups = viewsetActions(
  'licence-groups',
  {
    basePath: '/api/lms/',
  },
);

const definitions = viewsetActions(
  'definitions',
  {
    basePath: '/api/lms/',
    extraActions: {
      enable: { path: 'enable', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
    },
  },
);

const definitionsRequirements = viewsetActions(
  'definition-requirements',
  {
    basePath: '/api/lms/',
    extraActions: {
      enable: { path: 'enable', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
    },
  },
);

export default {
  applicationPortals,
  definitions,
  definitionsRequirements,
  licenceSubGroups,
  licenceGroups,
};
