<template>
  <v-row>
    <v-col
      cols="12"
      :sm="smCols"
      :md="mdCols"
      :lg="lgCols"
      :xl="xlCols"
    >
      <v-autocomplete
        v-model="year"
        :items="yearItems"
        :label="yearFullLabel"
        placeholder="Select or type"
        :rules="[(v) => !!v || 'Required']"
        :disabled="disabled"
        required
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      :sm="smCols"
      :md="mdCols"
      :lg="lgCols"
      :xl="xlCols"
    >
      <v-autocomplete
        v-model="month"
        :items="monthItems"
        :label="monthFullLabel"
        placeholder="Select or type"
        :rules="[(v) => v !== null || 'Required']"
        :disabled="disabled"
        required
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      :sm="smCols"
      :md="mdCols"
      :lg="lgCols"
      :xl="xlCols"
    >
      <v-autocomplete
        v-model="day"
        :items="dayItems"
        :label="dayFullLabel"
        placeholder="Select or type"
        :rules="[(v) => !!v || 'Required']"
        :disabled="disabled || !year || month === null"
        required
        @change="handleChange"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DateSelector',
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    returnDateObject: {
      type: Boolean,
      default: false,
    },
    future: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    breakpoint: {
      type: String,
      default: 'md',
    },
    yearLabel: {
      type: String,
      default: 'Year',
    },
    monthLabel: {
      type: String,
      default: 'Month',
    },
    dayLabel: {
      type: String,
      default: 'Day',
    },
    appendLabel: {
      type: String,
      default: null,
    },
    prependLabel: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    year: null,
    month: null,
    day: null,
    yearItems: [],
    fullMonthItems: [
      { value: 0, text: 'January' },
      { value: 1, text: 'February' },
      { value: 2, text: 'March' },
      { value: 3, text: 'April' },
      { value: 4, text: 'May' },
      { value: 5, text: 'June' },
      { value: 6, text: 'July' },
      { value: 7, text: 'August' },
      { value: 8, text: 'September' },
      { value: 9, text: 'October' },
      { value: 10, text: 'November' },
      { value: 11, text: 'December' },
    ],
  }),
  computed: {
    yearFullLabel() {
      if (this.appendLabel) {
        return `${this.yearLabel} ${this.appendLabel}`;
      }
      if (this.prependLabel) {
        return `${this.prependLabel} ${this.yearLabel.toLowerCase()}`;
      }
      return this.yearLabel;
    },
    monthFullLabel() {
      if (this.appendLabel) {
        return `${this.monthLabel} ${this.appendLabel}`;
      }
      if (this.prependLabel) {
        return `${this.prependLabel} ${this.monthLabel.toLowerCase()}`;
      }
      return this.monthLabel;
    },
    dayFullLabel() {
      if (this.appendLabel) {
        return `${this.dayLabel} ${this.appendLabel}`;
      }
      if (this.prependLabel) {
        return `${this.prependLabel} ${this.dayLabel.toLowerCase()}`;
      }
      return this.dayLabel;
    },
    dayItems() {
      if (this.year && this.month !== null) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        let totalDays = new Date(this.year, this.month + 1, 0).getDate();
        let startDay = 0;
        if (this.year === currentYear && this.month === currentMonth) {
          startDay = this.future ? currentDay : 0;
          totalDays = this.future ? totalDays - currentDay : currentDay;
        }
        return Array.from({ length: totalDays }, (_, i) => startDay + i + 1);
      }
      return [];
    },
    monthItems() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      if (this.year === currentYear) {
        return this.fullMonthItems.filter((item) => (
          this.future ? item.value >= currentMonth : item.value <= currentMonth
        ));
      }
      return this.fullMonthItems;
    },
    smCols() {
      return this.breakpoint === 'sm' ? '12' : '4';
    },
    mdCols() {
      return ['md', 'lg', 'xl'].includes(this.breakpoint) ? '12' : '4';
    },
    lgCols() {
      return ['lg', 'xl'].includes(this.breakpoint) ? '12' : '4';
    },
    xlCols() {
      return ['xl'].includes(this.breakpoint) ? '12' : '4';
    },
  },
  watch: {
    value() {
      this.parseValue();
    },
    dayItems(val) {
      if (this.day && !val.includes(this.day)) {
        this.day = null;
      }
    },
  },
  created() {
    const currentYear = new Date().getFullYear();
    this.yearItems = Array.from({ length: 100 }, (_, i) => (
      this.future ? currentYear + i : currentYear - i
    ));
    this.parseValue();
  },
  methods: {
    parseValue() {
      if (this.value instanceof Date) {
        this.year = this.value.getFullYear();
        this.month = this.value.getMonth();
        this.day = this.value.getDate();
        this.emitInput();
      } else if (this.value) {
        this.year = parseInt(this.value.substring(0, 4), 10);
        this.month = parseInt(this.value.substring(5, 7), 10) - 1;
        this.day = parseInt(this.value.substring(8, 10), 10);
        this.emitInput();
      } else if (!this.year && !this.month && !this.day) {
        this.year = null;
        this.month = null;
        this.day = null;
      }
    },
    handleChange() {
      this.emitInput();
    },
    emitInput() {
      let dob = null;
      if (this.year && this.month !== null && this.day) {
        dob = new Date(Date.UTC(this.year, this.month, this.day));
        if (!this.returnDateObject) {
          dob = dob.toISOString().substring(0, 10);
        }
      }
      this.$emit('input', dob);
    },
  },
};
</script>
