export default [
  {
    path: '/definitions',
    component: () => import(
      /* webpackChunkName: "definition" */
      '@/pages/lms/product/config/DefinitionManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/List.vue'
        ),
        name: 'definition-list',
        meta: {
          permissions: ['lms_definitions.view_definition'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/Add.vue'
        ),
        name: 'definition-add',
        meta: {
          permissions: ['lms_definitions.add_definition'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/Detail.vue'
        ),
        props: true,
        meta: {
          permissions: ['lms_definitions.view_definition'],
        },
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/Overview.vue'
            ),
            name: 'definition-overview',
            meta: {
              permissions: ['lms_definitions.view_definition'],
            },
          },
          {
            path: 'details/',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/Details.vue'
            ),
            name: 'definition-details',
            meta: {
              permissions: ['lms_definitions.change_definition'],
            },
          },
          {
            path: 'requirements/',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/Requirements.vue'
            ),
            name: 'definition-requirements',
            meta: {
              permissions: ['lms_definitions.change_definition'],
            },
          },
          {
            path: 'application-portals/',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/application_portals/List.vue'
            ),
            name: 'definition-application-portals',
            meta: {
              permissions: ['lms_definitions.change_applicationportal'],
            },
          },
          {
            path: 'application_portals/add',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/application_portals/Add.vue'
            ),
            name: 'definition-application-portals-add',
            meta: {
              permissions: ['lms_definitions.add_applicationportal'],
            },
          },
          {
            path: 'application_portals/:appId',
            name: 'definition-application-portals-details',
            component: () => import(
              /* webpackChunkName: "definition" */
              '@/components/lms/product/definitions/detail_tabs/application_portals/Details.vue'
            ),
            props: true,
            meta: {
              permissions: ['lms_definitions.change_applicationportal'],
            },
          },
        ],
      },
    ],
  },
];
