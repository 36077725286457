<template>
  <v-form
    v-if="!fetchingData"
    ref="form"
    v-model="formValid"
    @submit.prevent="formSubmit"
  >
    <v-select
      v-model="form.licence_type"
      :items="[{ text: 'Photocard', value: 'photo' }, { text: 'Papercard', value: 'paper' }]"
      :rules="[(v) => !!v || 'Required']"
      label="Type"
      :disabled="form.source === 'idsp'"
    />
    <v-autocomplete
      v-model="form.country"
      :items="countryItems"
      :rules="[(v) => !!v || 'Required']"
      label="Country of issue"
      placeholder="Start typing to search..."
      :disabled="form.source === 'idsp'"
    />
    <v-alert
      v-if="invalidDbsCountry"
      class="mt-4"
      type="warning"
    >
      The selected country is not valid for a DBS application
      due to their list being outdated. Please select an alternative
      value from the list below.
    </v-alert>
    <v-autocomplete
      v-if="invalidDbsCountry"
      v-model="form.country_dbs"
      :items="ebulkCountryItems"
      :rules="[(v) => !!v || 'Required']"
      label="DBS country of issue"
      placeholder="Start typing to search..."
    />
    <DateSelector
      v-model="form.dob"
      append-label="of birth"
      :disabled="form.source === 'idsp'"
    />
    <v-text-field
      ref="licence"
      v-model="form.number"
      label="Licence number"
      :rules="[(v) => !!v || 'Required', (v) => (v && v.length <= 18) || 'Max 18 characters']"
      :disabled="form.source === 'idsp'"
      @keyup="uppercase"
    />
    <v-checkbox
      v-if="showSurnameCheckbox"
      v-model="noSurname"
      label="Subject doesn't have a surname"
      @change="$refs.licence.validate"
    />
    <DateSelector
      v-model="form.issue_date"
      prepend-label="Issue"
      :disabled="form.source === 'idsp'"
    />
    <DateSelector
      v-model="form.expiry_date"
      prepend-label="Expiry"
      future
      :disabled="form.source === 'idsp'"
    />

    <v-row v-show="showConfirmation">
      <v-col>
        <p>Confirm the following:</p>
        <v-checkbox
          v-model="confirmation.subjectConsent"
          label="I have consent from the Subject to submit this data"
          :rules="[(v) => !!v || 'Please confirm']"
        />
        <v-checkbox
          v-model="confirmation.understandUpdate"
          label="I understand this form updates data on this case only, not other cases or the subject record"
          :rules="[(v) => !!v || 'Please confirm']"
        />
      </v-col>
    </v-row>

    <v-btn
      type="submit"
      color="primary"
      class="mt-8"
      :disabled="!formValid"
      :loading="loading"
    >
      <v-icon left>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>
    <v-btn
      v-if="!isNew"
      color="error"
      class="ml-2 mt-8"
      :loading="deleting"
      :disabled="form.source === 'idsp'"
      @click="remove"
    >
      <v-icon left>
        mdi-content-save
      </v-icon>
      Delete
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import DateSelector from '@/components/common/DateSelector.vue';

export default {
  name: 'CaseDrivingLicence',
  components: {
    DateSelector,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formValid: false,
    isNew: false,
    form: {
      licence_type: '',
      number: '',
      country: '',
      country_dbs: '',
      dob: null,
      issue_date: null,
      expiry_date: null,
      source: '',
    },
    showSurnameCheckbox: false,
    noSurname: false,
    fetchingData: false,
    loading: true,
    loadingError: false,
    deleting: false,
    showConfirmation: false,
    confirmation: {
      subjectConsent: false,
      understandUpdate: false,
    },
  }),
  computed: {
    ...mapGetters('utils', ['countryItems', 'ebulkCountryItems']),
    ...mapState('utils', ['countryMap']),
    invalidDbsCountry() {
      return this.isEbulk && this.form.country && this.countryMap[this.form.country].ebulk_missing;
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseDrivingLicences
        : this.$service.product.caseDrivingLicences;
    },
  },
  watch: {
    form: {
      handler() {
        if (!this.loading) {
          this.showConfirmation = true;
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getDrivingLicence();
    this.loading = false;
  },
  methods: {
    uppercase() {
      this.form.number = this.form.number ? this.form.number.toUpperCase() : '';
    },
    async getDrivingLicence() {
      this.fetchingData = true;
      const resp = await this.sv.retrieve(this.caseId);
      if (resp.status === 200) {
        this.form = resp.data;
      } else if (resp.status === 404) {
        this.isNew = true;
      }
      this.fetchingData = false;
    },
    async formSubmit() {
      this.loading = true;
      this.form.number = this.form.number.toUpperCase();
      if (this.isNew) {
        const resp = await this.sv.create({ case: this.caseId, ...this.form });
        if (resp.status === 201) {
          this.clearConfirmation();
          this.isNew = false;
        }
      } else {
        const resp = await this.sv.update(this.caseId, this.form);
        if (resp.status === 200) {
          this.clearConfirmation();
        }
      }
      this.loading = false;
    },
    clearConfirmation() {
      this.showConfirmation = false;
      this.confirmation = {
        subjectConsent: false,
        understandUpdate: false,
      };
    },
    async remove() {
      this.deleting = true;
      const resp = await this.sv.delete(this.caseId);
      if (resp.status === 204) {
        this.$refs.form.reset();
        this.isNew = true;
      }
      this.deleting = false;
    },
  },
};
</script>
